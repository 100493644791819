import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroHeader from '../components/heroHeader'
// import WpEditButton from "../components/WpEditButton"
import { processHtml } from "../utils/processHtml"

import PostsGridEachCateg from "../components/postsGridEachCateg"

import * as style from "./single.module.css"

const PageTemplate = ({ data }) => {
  const page = data.wpPage
  // const ogImageUrl = page.seo.opengraphImage?.localFile.publicURL || page.gatsbyCustomFields?.heroImage?.localFile.publicURL;
  // const ogImageUrl = post.gatsbyCustomFields.heroImage?.mediaItemUrl;

  const reactPage = processHtml(page.content, page.title)

  return (
    <Layout>

      {/* // Button to take us straight to WP editor of this post, for convenience while we get the site set up */}
      {/* <WpEditButton databaseId={page.databaseId} uri={page.uri} /> */}

      <Seo
        title={page.title}
        seo={page.seo}
      // ogImageUrl={ogImageUrl}
      />

      <HeroHeader
        post={page}
        pageOrPost='PAGE'
      />

      <article className={`content-container ${style.article}`}>
        <div className={style.article__content}>
          {reactPage}
        </div>
      </article>

      {/* <section> */}
      {/* <h2>{title}</h2> */}
      <PostsGridEachCateg />
      {/* <PostsGrid posts={posts.nodes} /> */}
      {/* <Link to={`/${id}/`}>(more blogs on {id}...)</Link> */}
      {/* </section> */}


    </Layout>
  )
}

export const query = graphql`
fragment seoPageData on WpPage {
  seo {
    title
    metaDesc
    opengraphImage {
      localFile {
        publicURL
      }
    }
  }
}
  query ($databaseId: Int!) {
    wpPage(databaseId: {eq: $databaseId}) {
      title
      uri
      content
      author {
        node {
          name
        }
      }
      date
      ...seoPageData
      gatsbyCustomFields {
        heroImage {
          altText
          mediaItemUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        heroImageSubjectHorizontalPos
        titleAsTwoParts
      }
    }
  }
`

export default PageTemplate