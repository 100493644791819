import React from 'react';

import Img from "gatsby-image"

import WaveHeader from './waveHeader';
import HeaderText from './headerText';

import * as style from "./hero.module.css"

const HeroHeader = (props) => {

    const { post = {} } = props;
    // console.log('HeroHeader', post?.title)

    let heroImage = props.heroImage || post.gatsbyCustomFields?.heroImage;
    if (heroImage && !heroImage.localFile.childImageSharp) {
        console.error(`heroImage not set - probably that weird warning: "You can't use childImageSharp together with .jpeg. — use publicURL instead. The childImageSharp portion of the query in this file will return null"`)  // ??? need to fix this!
        heroImage = null;
    }

    const horiz = (post.gatsbyCustomFields?.heroImageSubjectHorizontalPos || 'center')
        .replace(/left/i, '15%')
        .replace(/right/i, '85%');
    const objectPosition = `${horiz} top`

    return (

        heroImage ? (
            <div className={style.hero}>
                <Img
                    // fixed={heroImage.localFile.childImageSharp.fixed}
                    fluid={heroImage.localFile.childImageSharp.fluid}
                    alt={heroImage.altText}
                    // imgStyle={{ objectFit: "cover" }}
                    style={{ minHeight: "600px" }}
                    imgStyle={{
                        objectPosition,
                        top: "300px",                        // this and transform put the image centred vertically
                        transform: "translate(0%,-50%)",
                    }}
                />
                <HeaderText {...props} />
            </div>
        )
            : <WaveHeader {...props} />

    )
}


export default HeroHeader;
