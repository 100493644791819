import React from 'react'
import { graphql, useStaticQuery, Link } from "gatsby"
import PostsGrid from './postsGrid'

const PostsGridEachCateg = () => {

  const data = useStaticQuery(graphql`
    {
      pregnancyPosts: allWpPost(
        sort: {fields: date, order: DESC}
        limit: 3
        filter: {categories: {nodes: {elemMatch: {name: {eq: "pregnancy"}}}}}
      ) {
        nodes {
          ...postDetails
        }
      }
      birthPosts: allWpPost(
        sort: {fields: date, order: DESC}
        limit: 3
        filter: {categories: {nodes: {elemMatch: {name: {eq: "birth"}}}}}
      ) {
        nodes {
          ...postDetails
        }
      }
      babyPosts: allWpPost(
        sort: {fields: date, order: DESC}
        limit: 3
        filter: {categories: {nodes: {elemMatch: {name: {eq: "baby"}}}}}
      ) {
        nodes {
          ...postDetails
        }
      }
      mamaPosts: allWpPost(
        sort: {fields: date, order: DESC}
        limit: 3
        filter: {categories: {nodes: {elemMatch: {name: {eq: "mama"}}}}}
      ) {
        nodes {
          ...postDetails
        }
      }
    }
    
    fragment postDetails on WpPost {
      date
      databaseId
      excerpt
      uri
      slug
      title
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 330, quality: 70) {   # ??? i should make this DRY
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    
    `
  )

  return (

    <div className='main-container'>
      <MiniHub
        id='pregnancy'
        title='Blogs on ethical pregnancy'
        posts={data.pregnancyPosts.nodes}
      />

      <MiniHub
        id='birth'
        title='Blogs on ethical birth'
        posts={data.birthPosts.nodes}
      />

      <MiniHub
        id='baby'
        title='Blogs on ethical baby-raising'
        posts={data.babyPosts.nodes}
      />

      <MiniHub
          id='mama'
          title='blogs on ethical motherhood'
          posts={data.mamaPosts.nodes}
        />
    </div>

  )
}


function MiniHub({
  id,         // = 'pregnancy', 'birth', 'baby' or 'mama'
  title,
  posts
}) {

  return (
    <section>
      {/* <React.Fragment> */}
      <h2>{title}</h2>
      <PostsGrid posts={posts} />
      <Link to={`/${id}/`}>(more blogs on {id}...)</Link>
      {/* </React.Fragment> */}
    </section>
  )
}


export default PostsGridEachCateg
